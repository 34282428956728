<template>
    <form class="contact-form-1" @submit.prevent="sendEmail">
        <div class="form-group">
            <input
                type="text"
                name="fullname"
                :placeholder="$t('contact.form.fullName')"
                required
                v-model="fullName"
            />
        </div>

        <div class="form-group">
            <input
                type="email"
                name="email"
                :placeholder="$t('contact.form.email')"
                required
                v-model="email"
            />
        </div>

        <div class="form-group">
            <input
                type="text"
                name="phone"
                :placeholder="$t('contact.form.phone')"
                required
                v-model="phone"
            />
        </div>


        <div class="form-group">
            <input
                type="text"
                name="subject"
                :placeholder="$t('contact.form.subject')"
                required
                v-model="subject"
            />
        </div>

        <div class="form-group">
                <textarea
                    name="message"
                    :placeholder="$t('contact.form.message')"
                    required
                    v-model="message">
                </textarea>
        </div>

        <div class="form-group">
            <button
                @click="showCaptcha = true"
                v-if="!showCaptcha"
                class="btn-default btn-large"
            >{{ $t('contact.form.humanVerification') }}
            </button>
            <vue-hcaptcha
                v-if="showCaptcha"
                sitekey="b2cbf52b-22b2-4d00-be44-78baf662ee8b"
                @verify="onVerify"
                @expired="onExpire"
                @challenge-expired="onExpire"
                @error="onError"
            />
        </div>

        <div class="form-group">
            <button
                v-if="showCaptcha"
                class="btn-default btn-large"
                :disabled="isSending"
            >{{ $t('contact.form.sendRequest') }} <Icon v-if="isSending" name="loader" />
            </button>
        </div>

        <div class="form-group" v-html="showResult ? result : null"/>
    </form>
</template>

<script>
import axios from 'axios'
import Icon from "@/components/icon/Icon";
const client = axios.create({
    baseURL: 'https://43pngzlvmuuspuzzppu7crhtua0gdivf.lambda-url.eu-central-1.on.aws',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
});
export default {
    name: 'ContactForm',
    components: {
        Icon,
        'vue-hcaptcha': () => import('@hcaptcha/vue-hcaptcha')
    },
    data() {
        return {
            showCaptcha: false,
            verified: false,
            expired: false,
            token: null,
            eKey: null,
            error: null,
            fullName: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            isSending: false,
            showResult: false,
            result: ''
        }
    },
    methods: {
        onVerify(token, ekey) {
            this.verified = true;
            this.token = token;
            this.eKey = ekey;
        },
        onExpire() {
            this.verified = false;
            this.token = null;
            this.eKey = null;
            this.expired = true;
        },
        onError(err) {
            this.token = null;
            this.eKey = null;
            this.error = err;
        },
        sendEmail() {
            this.isSending = true
            if (!this.token) {
                this.result = `<p>${this.$t('contact.form.humanVerificationReason')}</p>`
                this.showResult = true
                this.isSending = false
                return;
            } else if (this.expired) {
                this.showCaptcha = false
                this.expired = false
                this.result = `<p>${this.$t('contact.form.humanVerificationExpired')}</p>`
                this.showResult = true
                this.isSending = false
                this.showCaptcha = true
                return;
            } else {
                this.result = ''
                this.showResult = false
            }

            client.post('/', {
                hcaptchaToken: this.token,
                fullName: this.fullName,
                email: this.email,
                phone: this.phone,
                subject: this.subject,
                message: this.message,
            }).then(() => {
                this.isSending = false
                this.result = `<p>${this.$t('contact.form.success')}</p>`
                this.showResult = true
            }).catch(() => {
                this.isSending = false
                this.result = `<p>${this.$t('contact.form.failure')}</p>`
                this.showResult = true
            })
        }
    }
}
</script>
