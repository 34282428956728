<template>
    <ul class="mainmenu">
        <li><router-link :to="'/' + this.$route.params.locale">{{ $t('menu.home') }}</router-link></li>
        <li><router-link :to="'/' + this.$route.params.locale + '/contact'">{{ $t('menu.contact') }}</router-link></li>
        <LocaleSwitcher />
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    import LocaleSwitcher from "@/components/i18n/LocaleSwitcher";

    export default {
        name: 'Nav',
        components: {LocaleSwitcher, Icon},
        data() {
            return {
                icons: {
                }
            }
        }
    }
</script>
