<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2665.708872272641!2d11.151032115646897!3d48.07726207921873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479c2c608e87f487%3A0x34dcf5af5e9bd84!2sPfarrgasse%203%2C%2082266%20Inning%20am%20Ammersee%2C%20Deutschland!5e0!3m2!1sde!2sbr!4v1647355132321!5m2!1sde!2sbr" width="600" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <!-- End Google MAp -->
</template>

<script>
    export default {
        name: 'GoogleMap'
    }
</script>
