<template>
    <div class="logo">
        <router-link :to="'/' + $route.params.locale + url">
            <img class="logo-light" :src="lightLogo" alt="Corporate Logo"/>
            <img class="logo-dark" :src="darkLogo" alt="Corporate Logo"/>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Logo',
        props: {
            url: {
                type: String,
                default: ''
            },
            lightLogo: {
                type: String,
                default: 'https://ik.imagekit.io/15mpn5sw9xb/octomated/logo/logo-transparent-light_rP50n8J0BO.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657012452049'
            },
            darkLogo: {
                type: String,
                default: 'https://ik.imagekit.io/15mpn5sw9xb/octomated/logo/logo-transparent-dark_2idrWKAV-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657012450829'
            }
        }
    }
</script>
