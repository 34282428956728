import Vue from 'vue'
import VueRouter from 'vue-router'
import ContactPage from '../views/ContactPage'
import NotFoundPage from '../views/404'
import Imprint from "@/views/Imprint";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import Business from "@/views/home-pages/Business";
import VueMeta from "vue-meta";

Vue.use(VueRouter)
Vue.use(VueMeta, {
    refreshOnceOnNavigation: false,
})

const routes = [
    {
        path: '/',
        redirect: `/en`,
    },
    {
        path: '/:locale',
        component: {
            template: '<router-view />',
        },
        children: [
            {
                path: '',
                name: 'Home',
                component: Business,
            },
            {
                path: 'imprint',
                name: 'Imprint',
                component: Imprint,
            },
            {
                path: 'privacy',
                name: 'PrivacyPolicy',
                component: PrivacyPolicy,
            },
            {
                path: 'contact',
                name: 'Contact',
                component: ContactPage,
            },
            {
                path: '*',
                name: 'NotFoundPage',
                component: NotFoundPage,
            },
        ],
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
})

router.beforeEach((to, from, next) => {
    next();
    window.scrollTo(0, 0)
});

export default router
