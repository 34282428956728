<template>
    <div :class="{'header-transparent-with-topbar': data.headerTransparency}">
        <div v-if="data.showTopHeader"
             class="header-top-bar">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="header-left">
                            <p><router-link :to="`/${$route.params.locale}/contact`">{{ $t('header.contactCta') }} <Icon name="chevron-right"/></router-link></p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="header-right">
                            <div class="address-content">
                                <p><a target="_blank" href="https://www.google.com/maps/place/Octomated+GmbH/@48.0773466,11.1529476,20.07z/data=!4m12!1m6!3m5!1s0x479c2dfa6e677cbf:0x65c48d8fdfa49b97!2sOctomated+GmbH!8m2!3d48.0772621!4d11.1532208!3m4!1s0x479c2dfa6e677cbf:0x65c48d8fdfa49b97!8m2!3d48.0772621!4d11.1532208"><Icon name="map-pin"/><span>{{ $t('header.location') }}</span></a></p>
                                <p><a href="tel:004981439903700"><Icon name="phone"/><span>+49 8143 990370-0</span></a></p>
                            </div>
                            <div class="social-icon-wrapper">
                                <ul class="social-icon social-default icon-naked">
                                    <li><a target="_blank" href="https://www.linkedin.com/company/octomated"><Icon name="linkedin"/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Start Header Area -->
        <header :class="[`rn-header header-default header-not-transparent header-sticky ${data.headerClass}`]">
            <div class="container position-relative">
                <div class="row align-items-center row--0">
                    <template v-if="data.navAlignment === 'right'">
                        <div class="col-lg-3 col-md-6 col-4">
                            <Logo/>
                        </div>
                        <div class="col-lg-9 col-md-6 col-8 position-static">
                            <div class="header-right">
                                <nav class="mainmenu-nav d-none d-lg-block">
                                    <Nav/>
                                </nav>

                                <!-- Start Mobile-Menu-Bar -->
                                <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div class="hamberger">
                                        <button class="hamberger-button"
                                                aria-label="Open navigation"
                                                @click.prevent="AppFunctions.toggleClass('.popup-mobile-menu', 'active')">
                                            <Icon name="menu" size="21"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="data.navAlignment === 'left'">
                        <div class="col-lg-9 col-md-6 col-4 position-static">
                            <div class="header-left d-flex">
                                <Logo/>
                                <nav class="mainmenu-nav d-none d-lg-block">
                                    <Nav/>
                                </nav>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-8">
                            <div class="header-right">
                                <!-- Start Mobile-Menu-Bar -->
                                <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div class="hamberger">
                                        <button class="hamberger-button"
                                                aria-label="Open navigation"
                                                @click.prevent="AppFunctions.toggleClass('.popup-mobile-menu', 'active')">
                                            <Icon name="menu" size="21"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </header>
        <!-- End Header Area -->

        <!-- Start Mobile Menu -->
        <MobileMenu/>
        <!-- End Mobile Menu -->

        <!-- Start Theme Style -->
        <div>
            <div class="rn-gradient-circle"/>
            <div class="rn-gradient-circle theme-pink"/>
        </div>
        <!-- End Theme Style -->
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import MobileMenu from './MobileMenu'
    import AppFunctions from '../../../helpers/AppFunctions'
    import Nav from './Nav'
    import Logo from '../../elements/logo/Logo'

    export default {
        name: 'Header',
        props: {
            data: {
                default: null
            }
        },
        components: {Logo, Nav, MobileMenu, Icon},
        data() {
            return {
                AppFunctions
            }
        },
        methods: {
            toggleStickyHeader() {
                const scrolled = document.documentElement.scrollTop;
                if (scrolled > 120) {
                    AppFunctions.addClass('.header-default', 'sticky');
                } else if (scrolled <= 130) {
                    AppFunctions.removeClass('.header-default', 'sticky');
                }
            }
        },
        created() {
            window.addEventListener('scroll', this.toggleStickyHeader);
        },
        mounted() {
            this.toggleStickyHeader();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.toggleStickyHeader);
        }
    }
</script>
