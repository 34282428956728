import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import 'animate.css'
import './assets/scss/style.scss'
import 'bootstrap'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueTypedJs from 'vue-typed-js'
import VueI18n from 'vue-i18n'
import {locales, translations} from "@/i18n/translations"
import Fragment from 'vue-fragment'

Vue.config.productionTip = false
Vue.use(VueScrollActive)
Vue.use(VueTypedJs)
Vue.use(VueI18n)
Vue.use(Fragment.Plugin)

const i18n = new VueI18n({
  messages: translations,
  fallbackLocale: 'en',
})

new Vue({
  i18n,
  router,
  created () {
    AOS.init({
      duration: 800,
      once: true,
    })
  },
  render: h => h(App)
}).$mount('#app')

const locale = router.currentRoute.params.locale
if (!locales.includes(locale)) {
  router.replace({path: '/en/'})
}
