<template>
    <Layout :footer-style="1">
        <div class="rn-blog-details-area">
            <div class="post-page-banner rn-section-gapTop">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content text-center">
                                <div class="page-title">
                                    <h1 class="theme-gradient">
                                        Impressum
                                    </h1>
                                </div>
                                <ul class="rn-meta-list">
                                    <li>
                                        <Icon name="user" class="size-20"/> <router-link to="#">Octomated GmbH</router-link>
                                    </li>
                                    <li>
                                        <Icon name="calendar" class="size-20"/> 21. März 2022
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-details-content pt--60 rn-section-gapBottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content">
                                <h4 class="mt-0">zugleich Angaben gemäß § 6 TDG, § 10 MDStV</h4>
                                <p>
                                    Octomated GmbH<br>
                                    Pfarrgasse 3<br>
                                    82266 Inning am Ammersee<br>
                                    Germany
                                </p>
                                <h4 class="mt-0">Kontakt</h4>
                                <p>
                                    Telefon: +49 8143 990370-0<br>
                                    E-Mail: info@octomated.com<br>
                                    Internet: https://www.octomated.com
                                </p>
                                <h4 class="mt-0">Registrierung</h4>
                                <p>
                                    Octomated GmbH<br>
                                </p>
                                <h4 class="mt-0">Gesellschaft mit beschränkter Haftung</h4>
                                <p>GmbH<br>
                                    HRB 271727<br>
                                    jeweils Amtsgericht München
                                </p>
                                <h4 class="mt-0">Geschäftsführung</h4>
                                <p>
                                    Eugen Heinrich<br>
                                    Philipp Wegner
                                </p>
                                <h4 class="mt-0">Zulassung / Aufsichtsbehörde</h4>
                                <p>
                                    Industrie- und Handelskammer für München und Oberbayern<br>
                                    Körperschaft des öffentlichen Rechts<br>
                                    Max-Joseph-Straße 2<br>
                                    80333 München<br>
                                    Tel: +49 89 5116-0<br>
                                    Fax: +49 89 5116-1306<br>
                                    E-Mail: info@muenchen.ihk.de
                                </p>
                                <h4 class="mt-0">Gewerbeamt des Landkreises</h4>
                                <p>
                                    Strandbadstr. 2<br>
                                    82319 Starnberg<br>
                                    Tel: +49 8151148468<br>
                                    Fax: +49 815114811468<br>
                                    E-Mail:	info@LRA-starnberg.de
                                </p>
                                <h4 class="mt-0">Haftungsausschluss</h4>
                                <h5 class="mt-0">Haftung für Inhalte</h5>
                                <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
                                    Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1
                                    TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
                                    wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                                    überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                                    Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                                    Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                                    möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                                </p>
                                <h5 class="mt-0">Haftung für Links</h5>
                                <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
                                    können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
                                    stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                                    Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                                    Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                                    Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                                    derartige Links umgehend entfernen.
                                </p>
                                <h5 class="mt-0">EU-Streitschlichtung</h5>
                                <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie
                                    über die Online-Streitbeilegungsplattform (OS -Plattform) informieren. Verbraucher haben die Möglichkeit,
                                    Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter
                                    https://ec.europa.eu/odr?tid=321125264 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
                                    unserem Impressum.
                                    Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
                                    Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import Icon from '../components/icon/Icon'

    export default {
        name: 'Imprint',
        components: {Icon, Layout, Breadcrumb},
        metaInfo() {
            return {
                title: this.$t('imprint.meta.title'),
                htmlAttrs: {
                    lang: 'de',
                },
                meta: [
                    {name: 'description', content: this.$t('imprint.meta.description')},
                    {name: 'og:title', content: this.$t('imprint.meta.title')},
                    {name: 'og:description', content: this.$t('imprint.meta.description')},
                    {name: 'og:locale', content: this.$route.params.locale},
                    {name: 'twitter:title', content: this.$t('imprint.meta.title')},
                    {name: 'twitter:description', content: this.$t('imprint.meta.description')},
                ],
            }
        },
    }
</script>
