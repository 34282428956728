export const portugueseTranslations = {
  header: {
    contactCta: 'Entre em contato conosco para discutir sua visão',
    location: 'Ammersee, Alemanha',
  },
  menu: {
    home: 'Inicio',
    contact: 'Contato',
  },
  home: {
    meta: {
      title: 'Software Manufactura | Octomated',
      description: 'Redução de emissões evitáveis através de soluções inteligentes de software.',
      keywords: 'software, soluções inteligentes para projetos digitais, emissões de carbono, co2, energia renovável',
    },
    hero: {
      title: 'Software Manufactura',
      description: 'O consumo de energia está aumentando, os recursos estão se tornando cada vez mais escassos. Acreditamos que os projetos digitais devem contrariar esta tendência. Nosso objetivo é reduzir as emissões evitáveis através de soluções inteligentes de software.',
      cta: 'Saiba mais aqui',
    },
    services: {
      title: 'Como funciona?',
      subtitle: 'Implementando sua visão em combinação com nosso know-how em TI',
      concept: {
        title: 'Conceito',
        description: 'Nesta fase, discutimos sua visão e juntos encontramos a melhor maneira de alcançá-la.'
      },
      development: {
        title: 'Desenvolvimento',
        description: 'Implementamos metas estabelecidas em etapas e as apresentamos a você. Os ajustes são possíveis a qualquer momento.'
      },
      provisioning: {
        title: 'Provisão',
        description: 'Infra-estruturas e software são colocados em operação de forma rápida, confiável e segura na nuvem por nós, utilizando técnicas de última geração.'
      },
      maintenanceAndMonitoring: {
        title: 'Manutenção & Monitoramento',
        description: 'Sejam atualizações de segurança, monitoramento de operações em andamento, extensões ou melhorias - estamos felizes em apoiá-lo.'
      },
    },
    contactCta: {
      action: 'Contato',
      description: 'Estamos prontos, você está?<br>Nossa equipe está ansiosa para ouvir de você.',
    },
  },
  footer: {
    privacy: 'Proteção de dados',
    imprint: 'Impresso',
  },
  contact: {
    meta: {
      title: 'Contato | Octomated',
      description: 'Nosso objetivo é reduzir as emissões evitáveis através de soluções inteligentes de software. Entre em contato conosco para discutir sua visão.',
    },
    title: 'Contato',
    subtitle: 'Entre em contato conosco',
    hotline: 'Linha direta',
    emails: 'E-Mails',
    location: 'Localização',
    form: {
      fullName: 'Sobrenome, Nome',
      email: 'E-Mail',
      phone: 'Telefone',
      subject: 'Assunto',
      message: 'Sua Mensagem',
      humanVerification: 'Carregamento de verificação humana',
      sendRequest: 'Enviar pedido de contato',
      humanVerificationReason: 'Gostaríamos de assegurar que o pedido de contato seja iniciado por um ser humano. Por favor, marque "Eu sou um ser humano" para continuar.',
      humanVerificationExpired: 'Infelizmente a verificação falhou porque passou muito tempo, por favor tente novamente.',
      success: 'Obrigado, seu pedido de contato foi enviado com sucesso.',
      failure: 'Desculpe, seu pedido de contato não pôde ser recebido.',
    },
    privacy: {
      meta: {
        title: 'Datenschutz | Octomated',
        description: 'Datenschutzerklärung der Octomated GmbH',
      },
    },
    imprint: {
      meta: {
        title: 'Impressum | Octomated',
        description: 'Impressum der Octomated GmbH',
      },
    },
  },
  notFound: {
    meta: {
      title: 'Não Encontrado | Octomated',
      description: 'A página especificada não foi encontrada.',
    },
    backButton: 'voltar para página inicial',
  },
}
