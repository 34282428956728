<template>
    <Layout>
        <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle
                            text-align="center"
                            :subtitle="$t('contact.subtitle')"
                            :title="$t('contact.title')"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="headphones" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">{{ $t('contact.hotline') }}</h4>
                                          <p><a href="tel:+4981439903700">+49 8143 990370-0</a></p>
                                          <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="mail" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">{{ $t('contact.emails') }}</h4>
                                          <p><a href="mailto:hello@octomated.com">hello@octomated.com</a></p>
                                          <p><a href="mailto:support@octomated.com">support@octomated.com</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="map-pin" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">{{ $t('contact.location') }}</h4>
                                            <p>Pfarrgasse 3</p>
                                            <p>DE-82266 Inning am Ammersee</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt--40 row--15">
                    <div class="col-lg-7">
                        <ContactForm/>
                    </div>
                    <div class="col-lg-5 mt_md--30 mt_sm--30">
                        <GoogleMap/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Contact Area  -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Icon from "../components/icon/Icon";
    import GoogleMap from "../components/elements/contact/GoogleMap";
    import ContactForm from "../components/elements/contact/ContactForm";

    export default {
        name: 'ContactPage',
        components: {ContactForm, GoogleMap, Icon, SectionTitle, Layout},
        metaInfo() {
            return {
                title: this.$t('contact.meta.title'),
                htmlAttrs: {
                    lang: this.$route.params.locale,
                },
                meta: [
                    {name: 'description', content: this.$t('contact.meta.description')},
                    {name: 'og:title', content: this.$t('contact.meta.title')},
                    {name: 'og:description', content: this.$t('contact.meta.description')},
                    {name: 'og:locale', content: this.$route.params.locale},
                    {name: 'twitter:title', content: this.$t('contact.meta.title')},
                    {name: 'twitter:description', content: this.$t('contact.meta.description')},
                ],
            }
        },
    }
</script>
