<template>
    <Layout :footer-style="1">
        <div class="rn-blog-details-area">
            <div class="post-page-banner rn-section-gapTop">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content text-center">
                                <div class="page-title">
                                    <h1 class="theme-gradient">
                                        Datenschutz
                                    </h1>
                                </div>
                                <ul class="rn-meta-list">
                                    <li>
                                        <Icon name="user" class="size-20"/> <router-link to="#">Octomated GmbH</router-link>
                                    </li>
                                    <li>
                                        <Icon name="calendar" class="size-20"/> 21. März 2022
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-details-content pt--60 rn-section-gapBottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content">
                                <h3 class="mt-0">Datenschutzerklärung</h3>
                                <p>
                                    Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
                                    Octomated GmbH (im Folgenden als „Octomated“ bzw. "Wir" bezeichnet), Pfarrgasse 3, 82266 Inning am Ammersee
                                </p>
                                <h4 class="mt-0">Ihre Betroffenenrechte</h4>
                                <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
                                <ul>
                                    <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
                                    <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                                    <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                                    <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
                                    <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
                                    <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
                                </ul>
                                <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
                                <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als
                                    verantwortliche Stelle zuständige Behörde.</p>
                                <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:
                                    <a href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html" target="_blank" rel="noopener nofollow">https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>.
                                </p>
                                <h4 class="mt-0">Erfassung allgemeiner Informationen beim Besuch unserer Website</h4>
                                <h5 class="mt-0">Art und Zweck der Verarbeitung:</h5>
                                <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese
                                    Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. </p>
                                <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
                                <ul>
                                    <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                                    <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                                    <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                                    <li>zur Optimierung unserer Website.</li>
                                </ul>
                                <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und
                                    die dahinterstehende Technik zu optimieren. </p>
                                <h5 class="mt-0">Rechtsgrundlage und berechtigtes Interesse:</h5>
                                <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.</p>
                                <h5 class="mt-0">Empfänger:</h5>
                                <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig werden.</p>
                                <h5 class="mt-0">Speicherdauer:</h5>
                                <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall,
                                    wenn die jeweilige Sitzung beendet ist. </p>
                                <p> Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer
                                    anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.</p>
                                <h5 class="mt-0">Bereitstellung vorgeschrieben oder erforderlich:</h5>
                                <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer
                                    Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen. </p>
                                <h4 class="mt-0">Cookies</h4>
                                <p>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert
                                    werden, wenn Sie unsere Webseite besuchen. </p>
                                <p>Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie Informationen und Anleitungen, wie diese Cookies gelöscht oder deren Speicherung vorab
                                    blockiert werden können. Je nach Anbieter Ihres Browsers finden Sie die notwendigen Informationen unter den nachfolgenden Links:</p>
                                <ul>
                                    <li>Mozilla Firefox: <a href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen" target="_blank" rel="nofollow noopener">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a>
                                    </li>
                                    <li>Internet Explorer: <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="nofollow noopener">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                                    </li>
                                    <li>Google Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank" rel="nofollow noopener">https://support.google.com/accounts/answer/61416?hl=de</a></li>
                                    <li>Opera: <a href="http://www.opera.com/de/help" target="_blank" rel="nofollow noopener">http://www.opera.com/de/help</a></li>
                                    <li>Safari: <a href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE" target="_blank" rel="nofollow noopener">https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</a>
                                    </li>
                                </ul>
                                <h5 class="mt-0">Speicherdauer und eingesetzte Cookies:</h5>
                                <p>Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung von Cookies erlauben, können folgende Cookies auf unseren Webseiten zum Einsatz kommen:</p>
                                <h4 class="mt-0">Technisch notwendige Cookies </h4>
                                <h5 class="mt-0">Art und Zweck der Verarbeitung: </h5>
                                <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel
                                    identifiziert werden kann.</p>
                                <p>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von
                                    Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</p>
                                <p>Für folgende Anwendungen benötigen wir Cookies:</p>
                                <h5 class="mt-0">Rechtsgrundlage und berechtigtes Interesse: </h5>
                                <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer nutzerfreundlichen Gestaltung unserer Website.</p>
                                <h5 class="mt-0">Empfänger: </h5>
                                <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden.</p>
                                <h5 class="mt-0">Bereitstellung vorgeschrieben oder erforderlich:</h5>
                                <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne diese Daten ist jedoch der Dienst und die Funktionsfähigkeit unserer
                                    Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein.</p>
                                <h5 class="mt-0">Widerspruch</h5>
                                <p>Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO weiter unten.</p>
                                <h4 class="mt-0">Technisch nicht notwendige Cookies</h4>
                                <p>Des Weiteren setzen wir Cookies ein, um das Angebot auf unserer Website besser auf die Interessen unserer Besucher abzustimmen oder auf Basis statistischer Auswertungen allgemein zu
                                    verbessern.</p>
                                <p>Welche Anbieter Cookies setzen, entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.</p>
                                <h5 class="mt-0">Rechtsgrundlage:</h5>
                                <p>Rechtsgrundlage für diese Verarbeitungen ist jeweils Ihre Einwilligung, Art. 6 Abs. 1 lit. a DSGVO.</p>
                                <h5 class="mt-0">Empfänger:</h5>
                                <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden. </p>
                                <p>Weitere Empfänger entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.</p>
                                <h5 class="mt-0">Drittlandtransfer:</h5>
                                <p>Informationen hierzu entnehmen Sie bitte aus den Auflistungen der einzelnen Darstellungs-, Tracking-, Remarketing- und Webanalyse-Anbietern.</p>
                                <h5 class="mt-0">Bereitstellung vorgeschrieben oder erforderlich:</h5>
                                <p>Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Webbrowser sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die
                                    Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren (siehe Widerruf der Einwilligung).</p>
                                <p>Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.</p>
                                <h5 class="mt-0">Widerruf der Einwilligung:</h5>
                                <p>Sie können Ihre Einwilligung jederzeit über unser Cookie-Consent-Tool widerrufen. </p>
                                <h5 class="mt-0">Profiling:</h5>
                                <p>Inwiefern wir das Verhalten von Websitebesuchern mit pseudonymisierten Nutzerprofilen analysieren, entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-,
                                    Tracking-, Remarketing- und Webanalyse-Technologien.</p>
                                <h4 class="mt-0">Newsletter</h4>
                                <h5 class="mt-0">Art und Zweck der Verarbeitung:</h5>
                                <p>Für die Zustellung unseres Newsletters erheben wir personenbezogene Daten, die über eine Eingabemaske an uns übermittelt werden.</p>
                                <p>Für eine wirksame Registrierung benötigen wir eine valide E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das
                                    „Double-Opt-in“-Verfahren ein. Hierzu protokollieren wir die Anmeldung zum Newsletter, den Versand einer Bestätigungsmail und den Eingang der hiermit angeforderten Antwort. Weitere Daten
                                    werden nicht erhoben. </p>
                                <h5 class="mt-0">Rechtsgrundlage:</h5>
                                <p>Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), übersenden wir Ihnen regelmäßig unseren Newsletter bzw. vergleichbare Informationen per E-Mail an Ihre
                                    angegebene E-Mail-Adresse. </p>
                                <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für die Zukunft widerrufen. In jedem Newsletter findet
                                    sich dazu ein entsprechender Link. Außerdem können Sie sich jederzeit auch direkt auf dieser Website abmelden oder uns Ihren Widerruf über die am Ende dieser Datenschutzhinweise angegebene
                                    Kontaktmöglichkeit mitteilen.</p>
                                <h5 class="mt-0">Empfänger:</h5>
                                <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
                                <h5 class="mt-0">Speicherdauer:</h5>
                                <p>Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. Danach werden sie gelöscht.</p>
                                <h5 class="mt-0">Bereitstellung vorgeschrieben oder erforderlich:</h5>
                                <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne bestehende Einwilligung können wir Ihnen unseren Newsletter leider nicht
                                    zusenden.</p>
                                <h5 class="mt-0">Widerruf der Einwilligung:</h5>
                                <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Die Abmeldung kann über den
                                    in jeder E-Mail enthaltenen Link oder beim unten aufgeführten Datenschutzbeauftragten bzw. der für den Datenschutz zuständigen Person beantragt werden. </p>
                                <h4 class="mt-0">Verwendung von Google Analytics</h4>
                                <p>Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website Google Analytics eingesetzt, ein Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA
                                    (nachfolgend: „Google“). Google Analytics verwendet sog. „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie
                                    ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
                                    Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
                                    des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im
                                    Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. </p>
                                <p>Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter
                                    <a href="https://www.google.com/analytics/terms/de.html und unter https://policies.google.com/?hl=de" rel="noopener" target="_blank">https://www.google.com/analytics/terms/de.html und unter
                                        https://policies.google.com/?hl=de</a>. </p>
                                <p>Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um
                                    weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. </p>
                                <p>Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren
                                    Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.</p>
                                <h5 class="mt-0">Widerruf der Einwilligung:</h5>
                                <p>Sie können das Tracking durch Google Analytics auf unserer Website unterbinden, indem Sie
                                    <a title="Google Analytics Opt-Out-Cookie setzen" onClick="gaOptout();alert('Google Analytics wurde deaktiviert');" href="#">diesen Link anklicken</a>.
                                    Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung durch Google Analytics für diese Website und für diesen Browser zukünftig verhindert,
                                    solange das Cookie in Ihrem Browser installiert bleibt.</p>
                                <p>Sie können darüber hinaus die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                                    gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. </p>
                                <p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten
                                    durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:
                                    <a href="http://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener" target="_blank">Browser Add On zur Deaktivierung von Google Analytics</a>.</p>
                                <h4 class="mt-0">Verwendung von Scriptbibliotheken (Google Webfonts)</h4>
                                <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain
                                    View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.</p>
                                <p>Weitere Informationen zu Google Web Fonts finden Sie unter
                                    <a href="https://developers.google.com/fonts/faq" rel="noopener nofollow" target="_blank">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google:
                                    <a href="https://www.google.com/policies/privacy/" rel="noopener nofollow" target="_blank">https://www.google.com/policies/privacy/</a>.</p>
                                <h4 class="mt-0">Verwendung von Google Maps</h4>
                                <p>Auf dieser Website nutzen wir das Angebot von Google Maps. Google Maps wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend „Google“) betrieben. Dadurch
                                    können wir Ihnen interaktive Karten direkt in der Webseite anzeigen und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.<br>Nähere Informationen über die Datenverarbeitung durch
                                    Google können Sie den Google-Datenschutzhinweisen entnehmen:
                                    <a href="https://policies.google.com/privacy" rel="noopener nofollow" target="_blank">https://policies.google.com/privacy</a>.
                                    Dort können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.</p>
                                <p>Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten finden Sie hier:
                                    <a href="https://www.dataliberation.org/" rel="noopener nofollow" target="_blank">https://www.dataliberation.org</a></p>
                                <p>Durch den Besuch der Website erhält Google Informationen, dass Sie die entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto
                                    bereitstellt, über das Sie eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. </p>
                                <p>Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons bei Google ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt
                                    sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner Websites. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur
                                    Erbringung bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung
                                    dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen. </p>
                                <h5 class="mt-0">Widerruf der Einwilligung:</h5>
                                <p>Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website
                                    verhindern wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In
                                    diesem Fall können Sie unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.</p>
                                <h4 class="mt-0">Google AdWords</h4>
                                <p>Unsere Website nutzt das Google Conversion-Tracking. Betreibergesellschaft der Dienste von Google AdWords ist die Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Sind Sie
                                    über eine von Google geschaltete Anzeige auf unsere Webseite gelangt, wird von Google Adwords ein Cookie auf Ihrem Rechner gesetzt. Das Cookie für Conversion-Tracking wird gesetzt, wenn ein
                                    Nutzer auf eine von Google geschaltete Anzeige klickt.</p>
                                <p>Besucht der Nutzer bestimmte Seiten unserer Website und das Cookie ist noch nicht abgelaufen, können wir und Google erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite
                                    weitergeleitet wurde. Jeder Google AdWords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Websites von AdWords-Kunden nachverfolgt werden. Die mithilfe des
                                    Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Kunden erfahren die
                                    Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit
                                    denen sich Nutzer persönlich identifizieren lassen.</p>
                                <p>Nähere Informationen über die Datenverarbeitung durch Google können Sie den Google-Datenschutzhinweisen entnehmen:
                                    <a href="https://policies.google.com/privacy" rel="noopener nofollow" target="_blank">https://policies.google.com/privacy</a>. Dort können Sie im Datenschutzcenter auch Ihre persönlichen
                                    Datenschutz-Einstellungen verändern.</p>
                                <h5 class="mt-0">Widerruf der Einwilligung:</h5>
                                <p>Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website
                                    verhindern wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In
                                    diesem Fall können Sie unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.</p>
                                <h4 class="mt-0">Cloudflare</h4>
                                <p>Wir nutzen das Content Delivery Network (CDN) von Cloudflare Germany GmbH, Rosental 7, c/o Mindspace, 80331 München Deutschland (Cloudflare), um die Sicherheit und die
                                    Auslieferungsgeschwindigkeit unserer Website zu erhöhen. Dies entspricht unserem berechtigten Interesse (Art. 6 Abs. 1 lit. f DSGVO). Ein CDN ist ein Netzwerk aus [weltweit] verteilten
                                    Servern,
                                    das in der Lage ist, optimiert Inhalte an den Websitenutzer auszuliefern. Für diesen Zweck können personenbezogene Daten in Server-Logfiles von Cloudflare verarbeitet werden. Bitte vergleichen
                                    Sie die Ausführungen unter „Hosting“.
                                    Cloudflare ist Empfänger Ihrer personenbezogenen Daten und als Auftragsverarbeiter für uns tätig. Die entspricht unserem berechtigten Interesse im Sinne des Art. 6 Abs. 1 S. 1 lit. f DSGVO,
                                    selbst kein Content Delivery Network zu betreiben.
                                    Sie haben das Recht der Verarbeitung zu widersprechen. Ob der Widerspruch erfolgreich ist, ist im Rahmen einer Interessenabwägung zu ermitteln.
                                    Die Verarbeitung der unter diesem Abschnitt angegebenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Die Funktionsfähigkeit der Website ist ohne die Verarbeitung nicht
                                    gewährleistet.
                                    Ihre personenbezogenen Daten werden von Cloudflare so lange gespeichert, wie es für die beschriebenen Zwecke erforderlich ist.
                                    Weitere Informationen zu Widerspruchs- und Beseitigungsmöglichkeiten gegenüber Cloudflare finden Sie unter: Cloudflare DPA
                                    Cloudflare hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Cloudflare personenbezogene Daten von natürlichen
                                    Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Weitere Informationen finden Sie unter:
                                    <a href="https://www.cloudflare.com/cloudflare_customer_SCCs-German.pdf" rel="noopener nofollow" target="_blank">https://www.cloudflare.com/cloudflare_customer_SCCs-German.pdf</a>
                                </p>
                                <h4 class="mt-0">SSL-Verschlüsselung</h4>
                                <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>

                                <h4 class="mt-0">hCaptcha</h4>
                                <p>Wir nutzen hCaptcha, ein Dienst von Intuition Machines, Inc. ("IMI"), einer Delaware US Corporation (#6393793), um uns zu schützen vor Kontaktanfragen welche nicht von realen Benutzern stammt. hCaptcha wird nur verwendet, falls Sie dem im Kontaktformular zustimmen durch anklicken von "Menschenverifizierung Laden". Das Kontaktformular ist ohne hCaptcha nicht funktionsfähig.</p>

                                <hr>
                                <h4 class="mt-0">Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h4>
                                <h5 class="mt-0">Einzelfallbezogenes Widerspruchsrecht</h5>
                                <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f
                                    DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4
                                    DSGVO.</p>
                                <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
                                    Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                                <h5 class="mt-0">Empfänger eines Widerspruchs</h5>
                                <p>
                                    Octomated GmbH<br>
                                    z.H. Eugen Heinrich<br>
                                    Pfarrgasse 3<br>
                                    82266 Inning a. Ammersee
                                </p>
                                <hr>
                                <h4 class="mt-0">Änderung unserer Datenschutzbestimmungen</h4>
                                <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
                                    Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
                                <h4 class="mt-0">Fragen an den Datenschutzbeauftragten</h4>
                                <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
                                <p>datenschutz@octomated.com</p>
                                <p>
                                    <em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für
                                        <a href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noopener">externe Datenschutzbeauftragte</a> (Version #2020-09-30).
                                    </em>
                                </p>

                                <div id="cookiefirst-policy-page"></div>
                                <div>Diese Cookie-Richtlinie wurde erstellt und aktualisiert von der Firma <a href="https://cookiefirst.com">CookieFirst.com</a>.</div>

                                <div id="cookiefirst-cookies-table"></div>
                                <div>Diese Cookie-Tabelle wurde erstellt und aktualisiert von der <a href="https://cookiefirst.com">CookieFirst consent management platform</a>.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import Icon from '../components/icon/Icon'

    export default {
        name: 'PrivacyPolicy',
        components: {Icon, Layout, Breadcrumb},

        metaInfo() {
            return {
                title: this.$t('privacy.meta.title'),
                htmlAttrs: {
                    lang: 'de',
                },
                meta: [
                    {name: 'description', content: this.$t('privacy.meta.description')},
                    {name: 'og:title', content: this.$t('privacy.meta.title')},
                    {name: 'og:description', content: this.$t('privacy.meta.description')},
                    {name: 'og:locale', content: this.$route.params.locale},
                    {name: 'twitter:title', content: this.$t('privacy.meta.title')},
                    {name: 'twitter:description', content: this.$t('privacy.meta.description')},
                ],
            }
        },
    }
</script>
