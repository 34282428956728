import {germanTranslations} from "@/i18n/lang/de";
import {englishTranslations} from "@/i18n/lang/en";
import {portugueseTranslations} from "@/i18n/lang/br";

export const translations = {
  en: {...englishTranslations},
  de: {...germanTranslations},
  br: {...portugueseTranslations},
}

export const locales = [
  'en', 'de', 'br',
];
