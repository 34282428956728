export const germanTranslations = {
  meta: {},
  header: {
    contactCta: 'Kontaktieren Sie uns, um Ihre Vision zu besprechen',
    location: 'Ammersee, Deutschland',
  },
  menu: {
    home: 'Home',
    contact: 'Kontakt',
  },
  home: {
    meta: {
      title: 'Software Manufaktur | Octomated',
      description: 'Reduzierung vermeidbarer Emissionen durch intelligente Software-Lösungen.',
      keywords: 'Software, intelligente digitale Projektlösungen, emissionen, co2, erneuerbare Energie',
    },
    hero: {
      title: 'Software Manufaktur',
      description: 'Der Energiekonsum steigt, Ressourcen werden knapper. Wir glauben, digitale Projekte sollten diesem Trend entgegenwirken. Unser Ziel ist die Reduzierung vermeidbarer Emissionen durch intelligente Software-Lösungen.',
      cta: 'Hier mehr erfahren',
    },
    services: {
      title: 'Wie funktioniert\'s?',
      subtitle: 'Ihre Vision in Verbindung mit unserem IT Know-How umsetzen',
      concept: {
        title: 'Konzeption',
        description: 'In dieser Phase besprechen wir Ihre Vision und finden gemeinsam einen optimalen Weg, um diese zu erreichen.'
      },
      development: {
        title: 'Entwicklung',
        description: 'Gesetzte Ziele werden etappenweise von uns in die Tat umgesetzt und Ihnen vorgestellt. Anpassungen sind jederzeit möglich.'
      },
      provisioning: {
        title: 'Bereitstellung',
        description: 'Infrastrukturen und Software werden mit hochmoderner Techniken schnell, zuverlässig und sicher in der Cloud von uns in Betrieb genommen.'
      },
      maintenanceAndMonitoring: {
        title: 'Wartung & Monitoring',
        description: 'Ob Sicherheitsupdates, Monitoring des laufenden Betriebs, Erweiterungen oder Verbesserungen – gerne unterstützen wir Sie.'
      },
    },
    contactCta: {
      action: 'Kontakt aufnehmen',
      description: 'Wir sind bereit, sind Sie es?<br>Unser Team freut sich auf Ihre Nachricht.',
    },
  },
  footer: {
    privacy: 'Datenschutz',
    imprint: 'Impressum',
  },
  contact: {
    meta: {
      title: 'Kontakt | Octomated',
      description: 'Unser Ziel ist die Reduzierung vermeidbarer Emissionen durch intelligente Software-Lösungen. Kontaktieren Sie uns, um Ihre Vision zu besprechen.',
    },
    title: 'Kontakt',
    subtitle: 'Setzen Sie sich mit uns in Verbindung',
    hotline: 'Hotline',
    emails: 'E-Mails',
    location: 'Standort',
    form: {
      fullName: 'Nachname, Vorname',
      email: 'E-Mail Addresse',
      phone: 'Telefon',
      subject: 'Betreff',
      message: 'Ihre Nachricht',
      humanVerification: 'Menschenverifizierung laden',
      sendRequest: 'Kontaktanfrage abschicken',
      humanVerificationReason: 'Wir möchten uns vergewissern, dass die Kontaktanfrage von einem Menschen initiiert wird. Bitte setzen Sie ein Häkchen bei "Ich bin ein Mensch" um fortzufahren.',
      humanVerificationExpired: 'Leider hat die Verifizierung fehlgeschlagen da zu viel Zeit vergangen ist, versuchen Sie es bitte erneut.',
      success: 'Vielen Dank, Ihre Kontaktanfrage wurde erfolgreich verschickt.',
      failure: 'Ihre Kontaktanfrage konnte leider nicht empfangen werden.',
    },
    privacy: {
      meta: {
        title: 'Datenschutz | Octomated',
        description: 'Datenschutzerklärung der Octomated GmbH',
      },
    },
    imprint: {
      meta: {
        title: 'Impressum | Octomated',
        description: 'Impressum der Octomated GmbH',
      },
    },
  },
  notFound: {
    meta: {
      title: 'Nicht Gefunden | Octomated',
      description: 'Die angegebene Seite konnte nicht gefunden werden.',
    },
    backButton: 'zurück zur Hauptseite',
  },
}
