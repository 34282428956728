export const englishTranslations = {
  header: {
    contactCta: 'Contact us to discuss your vision',
    location: 'Ammersee, Germany',
  },
  menu: {
    home: 'Home',
    contact: 'Contact',
  },
  home: {
    meta: {
      title: 'Software Manufactory | Octomated',
      description: 'Reducing avoidable emissions through intelligent software solutions.',
      keywords: 'software, intelligent digital project solutions, carbon emissions, renewable energy',
    },
    hero: {
      title: 'Software Manufactory',
      description: 'Energy consumption is rising, resources are becoming scarcer. We believe digital projects should counteract this trend. Our goal is to reduce avoidable emissions through intelligent software solutions.',
      cta: 'Learn more here',
    },
    services: {
      title: 'How does it work?',
      subtitle: 'Implementing your vision in combination with our IT know-how',
      concept: {
        title: 'Concept',
        description: 'In this phase we discuss your vision and together we find an optimal way to achieve it.'
      },
      development: {
        title: 'Development',
        description: 'We work on goals that have been set and present them to you in stages. Adjustments are possible at any time.'
      },
      provisioning: {
        title: 'Provisioning',
        description: 'Infrastructures and software are put into operation quickly, reliably and securely in the cloud by us using state-of-the-art techniques.'
      },
      maintenanceAndMonitoring: {
        title: 'Maintenance & Monitoring',
        description: 'Whether security updates, monitoring of ongoing operations, enhancements or improvements - we are happy to support you.'
      },
    },
    contactCta: {
      action: 'Contact us',
      description: 'We are ready, are you?<br>Our team is looking forward to your message.',
    },
  },
  footer: {
    privacy: 'Privacy',
    imprint: 'Imprint',
  },
  contact: {
    meta: {
      title: 'Contact | Octomated',
      description: 'Our goal is to reduce avoidable emissions through intelligent software solutions. Contact us to discuss your vision.',
    },
    title: 'Contact',
    subtitle: 'Get in touch with us',
    hotline: 'Hotline',
    emails: 'E-mails',
    location: 'Location',
    form: {
      fullName: 'Family name, First name',
      email: 'E-mail address',
      phone: 'Phone number',
      subject: 'Subject',
      message: 'Your message',
      humanVerification: 'Load Human Check',
      sendRequest: 'Send contact request',
      humanVerificationReason: 'We would like to make sure that the contact request is initiated by a human. Please check the "I\'m human" box to continue.',
      humanVerificationExpired: 'Unfortunately the verification failed because too much time has passed, please try again.',
      success: 'Thank you, your contact request has been sent successfully.',
      failure: 'Unfortunately, your contact request could not be received.',
    },
  },
  privacy: {
    meta: {
      title: 'Datenschutz | Octomated',
      description: 'Datenschutzerklärung der Octomated GmbH',
    },
  },
  imprint: {
    meta: {
      title: 'Impressum | Octomated',
      description: 'Impressum der Octomated GmbH',
    },
  },
  notFound: {
    meta: {
      title: 'Not Found | Octomated',
      description: 'The requested page could not be found.',
    },
    backButton: 'back to homepage',
  }
}
