<template>
    <fragment>
        <li v-for="locale in locales" @click.prevent="onLocaleChange(locale)"><router-link to=""><country-flag rounded :country="locale ==='en' ? 'us' : locale" /></router-link></li>
    </fragment>
</template>

<script>
import {locales} from "@/i18n/translations";
import Icon from "@/components/icon/Icon";
import CountryFlag from 'vue-country-flag'

export default {
    name: 'LocaleSwitcher',
    components: {Icon, CountryFlag},
    data () {
        return {
            locales
        }
    },
    methods: {
        onLocaleChange(locale) {
            const popupMenuClasses = document.querySelector('.popup-mobile-menu').classList
            if (popupMenuClasses.contains('active')) {
                popupMenuClasses.toggle('active')
            }
            if (this.$route.params.locale !== locale) {
                this.$i18n.locale = locale
                this.$route.params.locale = locale
                this.$router.replace(this.$route)
            }
        }
    }
}
</script>

<style scoped>
.country-icon {
    width: 40px;
}
.country-icon.as-toggle {
    margin-top: 5px;
}
</style>
