<template>
    <div class="row row--15 service-wrapper">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-stretch"
             v-for="(service, index) in serviceList"
             :key="index">
            <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="icon">
                    <Icon :name="service.icon" :class="`size-${iconSize}`"/>
                </div>
                <div class="content">
                    <h4 class="title w-600">
                        {{ $t(service.title) }}
                    </h4>
                    <p class="description b1 color-gray mb--0" v-html="$t(service.description)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
    name: 'ServiceOne',
    components: {Icon},
    props: {
        serviceStyle: {
            type: String
        },
        iconSize: {
            type: String | Number,
            default: 62
        },
        textAlign: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            serviceList: [
                {
                    icon: 'map',
                    title: 'home.services.concept.title',
                    description: 'home.services.concept.description',
                    link: '#'
                },
                {
                    icon: 'compass',
                    title: 'home.services.development.title',
                    description: 'home.services.development.description',
                    link: '#'
                },
                {
                    icon: 'globe',
                    title: 'home.services.provisioning.title',
                    description: 'home.services.provisioning.description',
                    link: '#'
                },
                {
                    icon: 'tool',
                    title: 'home.services.maintenanceAndMonitoring.title',
                    description: 'home.services.maintenanceAndMonitoring.description',
                    link: '#'
                },
            ]
        }
    },
    computed: {
        checkTextAlign() {
            if (this.textAlign === 'left') return 'text-start';
            else if (this.textAlign === 'center') return 'text-center';
            else if (this.textAlign === 'end') return 'text-end';
            else return '';
        }
    }
}
</script>
