<template>
    <Layout>
        <!-- Start Error-Area Area  -->
        <div class="error-area ptb--200 ptb_sm--60 ptb_md--80">
            <div class="container">
                <div class="row align-item-center">
                    <div class="col-lg-12">
                        <div class="error-inner">
                            <h1>404</h1>
                            <h2 class="title">{{ $t('notFound.meta.title') }}</h2>
                            <p class="description">{{ $t('notFound.meta.description') }}</p>
                            <form v-if="isSearchActive" action="#" class="blog-search">
                                <input type="text" placeholder="Search Here...">
                                <button class="search-button">
                                    <Icon name="search" size="20"/>
                                </button>
                            </form>
                            <br>
                            <div class="view-more-button">
                                <router-link class="btn-default" :to="`/${$route.params.locale}`">{{ $t('notFound.backButton') }}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Error-Area Area  -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'

    export default {
        name: 'NotFoundPage',
        components: {Icon, Layout},
        data() {
            return {
                isSearchActive: false
            }
        },
        metaInfo() {
            return {
                title: this.$t('notFound.meta.title'),
                htmlAttrs: {
                    lang: this.$route.params.locale,
                },
                meta: [
                    {name: 'description', content: this.$t('notFound.meta.description')}
                ],
            }
        },
    }
</script>
