<template>
    <Layout :footer-style="1" buy-button-class="btn-default btn-small">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-3 height-850">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                        <div class="inner text-left">
                            <h1 class="title theme-gradient" data-aos="fade-up" data-aos-delay="100">
                                {{ $t('home.hero.title') }}
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="130">
                                {{ $t('home.hero.description') }}
                            </p>
                            <div class="button-group mt--30 mt_sm--20" data-aos="fade-up" data-aos-delay="160">
                                <a class="btn-default btn-medium btn-icon"
                                   href="#services">
                                    {{ $t('home.hero.cta') }}
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="order-1 order-lg-2 col-lg-5 logo" data-aos="fade-up" data-aos-delay="100">
                          <img class="banner-logo-dark" src="https://ik.imagekit.io/15mpn5sw9xb/octomated/logo/logo-transparent-dark_2idrWKAV-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657012450829" alt="Octomated Logo"/>
                          <img class="banner-logo-light" src="https://ik.imagekit.io/15mpn5sw9xb/octomated/logo/logo-transparent-light_rP50n8J0BO.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657012452049" alt="Octomated Logo"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <Separator/>
        <a class="anchor-offset" id="services"></a>


        <!-- Start Service Area -->
        <section class="rn-service-area rn-section-gap">
          <div class="container">
            <div class="row row--30">
              <div class="col-lg-12">
                <SectionTitle
                    class="mb--50"
                    text-align="center"
                    :subtitle="$t('home.services.subtitle')"
                    :title="$t('home.services.title')"
                    data-aos="fade-up"
                    data-aos-delay="60"
                />
              </div>
            </div>
            <ServiceOne
                service-style="service__style--1 bg-color-blackest radius mt--25"
                icon-size="62"
                text-align="center"
            />
          </div>
        </section>
        <!-- End Service Area -->

        <Separator/>

        <CallToAction
            :style-type="6"
            :title="$t('home.contactCta.description')"
            :btn-text="$t('home.contactCta.action')"
        />
    </Layout>
</template>
<style>
a.anchor-offset {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
}
</style>
<script>
    import Layout from '../../components/common/Layout'
    import Icon from '../../components/icon/Icon'
    import Separator from '../../components/elements/separator/Separator'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import ServiceOne from '../../components/elements/service/ServiceOne'
    import CallToAction from "@/components/elements/callToAction/CallToAction";
    import ServiceTwo from "@/components/elements/service/ServiceTwo";

    export default {
        name: 'Business',
        components: {ServiceTwo, CallToAction, SectionTitle, ServiceOne, Separator, Icon, Layout},
        metaInfo() {
            return {
                title: this.$t('home.meta.title'),
                htmlAttrs: {
                    lang: this.$route.params.locale,
                },
                meta: [
                    {name: 'description', content: this.$t('home.meta.description')},
                    {name: 'og:title', content: this.$t('home.meta.title')},
                    {name: 'og:description', content: this.$t('home.meta.description')},
                    {name: 'og:locale', content: this.$route.params.locale},
                    {name: 'twitter:title', content: this.$t('home.meta.title')},
                    {name: 'twitter:description', content: this.$t('home.meta.description')},
                    {name: 'keywords', content: this.$t('home.meta.keywords')},
                ],
            }
        },
    }
</script>
